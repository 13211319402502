import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import SweetButton from '../sweet-button/SweetButton';

function Navbar() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const handleClick = () => {
    window.open('https://discord.gg/D4tVRss8dB', '_blank');
  };

  return (
    <nav className="navbar">
      <Link to={`/${lang}/start`} className="navbar-logo">
        <img src="/images/logo.png" alt="Logo" />
        Purge eSports
      </Link>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      <div className={`navbar-center ${isOpen ? 'open' : ''}`}>
        <ul className="navbar-links">
          <li onClick={closeMenu}><Link to={`/${lang}/start`}>{t('pages.start')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/coaching`}>{t('pages.coaching')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/teams`}>{t('pages.teams')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/tournaments`}>{t('pages.tournaments')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/partner`}>{t('pages.partner')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/merch`}>{t('pages.merch')}</Link></li>
          <li onClick={closeMenu}><Link to={`/${lang}/socials`}>{t('pages.socials')}</Link></li>
        </ul>
      </div>
      <div className="navbar-discord">
        <SweetButton className={`${isOpen ? 'open' : ''}`} to={`/${lang}/contact`}>{t('pages.contact')}</SweetButton>
      </div>
    </nav>
  );
}

export default Navbar;