import React, { useState } from 'react';
import './Start.css';
import DiscordButton from '../../components/discord-button/DiscordButton';
import GridBackground from '../../components/grid-background/GridBackground';
import FAQSection from '../../components/faq-section/FAQSection';

const iconClassMapping = {
  tiktok: 'fab fa-tiktok',
  discord: 'fab fa-discord',
  twitter: 'fab fa-twitter',
  instagram: 'fab fa-instagram',
  youtube: 'fab fa-youtube',
  twitch: 'fab fa-twitch',
};

const faqs = [
  {
    question: 'Was ist Purge eSports?',
    answer: 'Purge eSports ist eine eSport Organisation, welche viele verschiedene Leute zusammenbringt und ihnen Hilft besser zu werden und zu wachsen.'
  },
  {
    question: 'Wie kann ich Purge eSports beitreten?',
    answer: 'Du kannst Purge eSports beitreten, indem du entweder die Teamleader selbst kontaktierst oder auf unserem Discord Server ein Ticket erstellst.'
  },
  {
    question: 'In welchen Spielen ist Purge eSports aktiv?',
    answer: 'Purge eSports ist aktiv in Valorant, CS:GO, Rocket League, Fortnite und Rainbow Six Siege.'
  }
];

const displayedPlayers = [
  {
    name: 'KillstealGmbH',
    role: 'Owner',
    Image: '/images/killstealpic.webp',
    socials: {
      tiktok: 'https://www.tiktok.com/@killstealgmbh',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: 'https://www.youtube.com/@KillstealGmbH',
      twitch: 'https://www.twitch.tv/killstealgmbh'
    }
  },
  {
    name: 'HerobrineTVv',
    role: 'Co Owner',
    Image: '/images/Owner-PB-Banner.gif',
    socials: {
      tiktok: 'https://www.tiktok.com/@herobrinetvv',
      discord: '',
      twitter: '',
      instagram: '',
      youtube: 'https://www.youtube.com/@HerobrineTVv',
      twitch: 'https://www.twitch.tv/herobrinetvv'
    }
  }

  //,
  //{
  //  name: 'HerobrineTVv',
  //  role: 'Co Owner',
  //  Image: '/images/Owner-PB-Banner.gif',
  //  socials: {
  //    tiktok: 'https://www.tiktok.com/@purgeesports',
  //    discord: 'https://discord.gg/purgeesports',
  //    twitter: 'https://twitter.com/purgeesports',
  //    instagram: 'https://www.instagram.com/purgeesports/',
  //    youtube: 'https://www.instagram.com/purgeesports/',
  //    twitch: 'https://www.instagram.com/purgeesports/'
  //  }
  //}
]

function scrollLeft() {
  const container = document.querySelector('.players-container');
  container.scrollBy({ left: -200, behavior: 'smooth' });
}

function scrollRight() {
  const container = document.querySelector('.players-container');
  container.scrollBy({ left: 200, behavior: 'smooth' });
}

function Start() {
  return (
    <div className="page-container">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
        rel="stylesheet"
      />
      <div className="container">
        <GridBackground />
        <div className="grid-container">
          <div className="grid-item info-section">
            <div className="tagline">Valorant, CSGO, Rocket League, Fortnite & R6</div>
            <h1 className="text-appear-animation">Purge <br />eSports</h1>
            <p>
              Bei Purge eSports helfen wir Spielern und Coaches, das perfekte Team zu finden. Egal, ob du ein erfahrener Spieler oder ein aufstrebender Coach bist, wir bieten dir die Plattform und Unterstützung, die du benötigst, um erfolgreich zu sein.
            </p>
            <div className="buttons">
              <DiscordButton />
              <button className='match-history'>Match History</button>
            </div>
          </div>
          <div className="grid-item trikot-section">
            <div className="blue-circle"></div>
            <img src="/images/JerseyPrintfileFront-Purge.png" alt="Team Jersey" className="jersey-image" />
          </div>
          <div className="grid-item-mid info-section">
          <h2>Unser Team</h2>
            <div className="scroll-container">
              <button className="scroll-button left" onClick={scrollLeft}>&lt;</button>
              <div className="players-container">
                {displayedPlayers.map((player, index) => (
                  <div key={index} className="player-card">
                    <img src={player.Image} alt={player.name} className="player-image" style={{ width: '299.25px', height: '485px', objectFit: 'cover' }} />
                    <div className="player-info">
                      <h3>{player.name}</h3>
                      <p>{player.role}</p>
                      <div className="social-icons">
                        {Object.entries(player.socials).map(([name, url], idx) => (
                          url && (
                            <a key={idx} href={url} target="_blank" rel="noopener noreferrer" className="social-link">
                              <i className={iconClassMapping[name]}></i>
                            </a>
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button className="scroll-button right" onClick={scrollRight}>&gt;</button>
            </div>
          </div>
          {/* Empty Grid Setion */}
          <div  className="grid-item info-section"></div>
          <div className="grid-item-right info-section">
            <div className="tagline">Fragen & Antworten</div>
            <h1>FAQ</h1>
            <FAQSection faqs={faqs} />
          </div>
        </div>
      </div>
    </div>
  );
}

// Mitglieder Images wie auf cgn: https://www.cgn.gg/

export default Start;
