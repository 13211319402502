import './Socials.css';
import { FaTwitter, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';

function Socials() {
    return (
        <div className="socials-page">
            <h1 className="socials-title">Folge uns auf unseren Sozialen Medien</h1>
            <div className="socials-container">
                <a href="https://twitter.com/PRGPurgeeSports" target="_blank" rel="noopener noreferrer" className="social-links twitter">
                    <FaTwitter className="social-icon" />
                    <span>Twitter</span>
                </a>
                <a href="https://www.instagram.com/purge.esportsde" target="_blank" rel="noopener noreferrer" className="social-links instagram">
                    <FaInstagram className="social-icon" />
                    <span>Instagram</span>
                </a>
                <a href="https://www.youtube.com/@PRGeSportDE" target="_blank" rel="noopener noreferrer" className="social-links youtube">
                    <FaYoutube className="social-icon" />
                    <span>YouTube</span>
                </a>
                <a href="https://www.tiktok.com/@prgpurge" target="_blank" rel="noopener noreferrer" className="social-links tiktok">
                    <FaTiktok className="social-icon" />
                    <span>TikTok</span>
                </a>
            </div>
        </div>
    );
}

export default Socials;