import React from 'react';
import { Link } from 'react-router-dom';
import './SweetButton.css';

const SweetLinkButton = ({ className, to, children }) => {
    return (
        <Link to={to} className={`sw-button ${className}`}>
            {children}
        </Link>
    );
};

export default SweetLinkButton;