import React, { useState } from 'react';
import TeamSection from '../../components/coaching-container/CoachingContainer.js';
import './Coaching.css';

function Coaching() {
    return (
      <div className="page-container">
      <h1><br />Unsere Coaches</h1>
      <div className="container">
        <div className="grid-container-teams">
          <h2><br />Aktuell haben wir niemanden der Coachings anbietet!</h2>
          <p>Du möchtest ein Coaching anbieten? Melde dich bei uns auf Discord</p>
          {/* 
          <TeamSection 
             logoSrc="/logo192.png"
             coachName="Coach1"
             coachDesc="Lorem ipsum do invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
             coachGames={["Valorant"]}
             coachRank={["Radiant"]}
             onClick={() => window.open("https://google.com/")}
          /> 
          */}
        </div>
      </div>
      </div>
          //Wir suchen aktuell Coaches, wenn du Interessiert bist und Erfahrung im Coachen hast melde dich bei uns.
    );
  };
  
export default Coaching;